import React from "react";
import PropTypes from "prop-types";
import { Route, Link, Switch } from "react-router-dom";
// import firebase from 'firebase';
// import ReactGA from 'react-ga';
import Loadable from "react-loadable";

/** Import Each Page Component On-Demand * */

const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ "../Containers/Home"),
  loading: () => null,
  modules: ["home"],
});

const AsyncLogin = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "../Containers/Login"),
  loading: () => null,
  modules: ["login"],
});
const AsyncResetPassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "reset-password" */ "../Containers/ResetPassword"),
  loading: () => null,
  modules: ["reset-password"],
});
const AsyncPin = Loadable({
  loader: () => import(/* webpackChunkName: "pin" */ "../Containers/Pin"),
  loading: () => null,
  modules: ["pin"],
});

const AsyncError = Loadable({
  loader: () => import(/* webpackChunkName: "error" */ "../Containers/Error"),
  loading: () => null,
  modules: ["error"],
});

const Navigation = () => (
  <Switch>
    <Route exact path="/" component={AsyncHome} />
    <Route exact path="/login" component={AsyncLogin} />
    <Route
      exact
      path="/reset-password/:email/:key"
      component={AsyncResetPassword}
    />
    <Route exact path="/login/:scope/:grant" component={AsyncLogin} />
    <Route exact path="/pin/:scope/:grant" component={AsyncPin} />

    {/* Finally, catch all unmatched routes */}
    <Route path="*" component={AsyncError} />
  </Switch>
);
export default Navigation;
