import fb from "./firebase.json";

const LOCALHOST = "localhost";

const USE_EXPRESS = true;

// http://localhost:4000/api/.well-known/openid-configuration
const EXPRESS_ISSUER = {
  issuer: "http://localhost:4000/api",
  authorization_endpoint: "http://localhost:4000/api/auth",
  token_endpoint: "http://localhost:4000/api/token",
  userinfo_endpoint: "http://localhost:4000/api/me",
  jwks_uri: "http://localhost:4000/api/certs",
};

// http://localhost:4000/ideacloud-id-gateway/us-central1/api/.well-known/openid-configuration
const FUNCTIONS_ISSUER = {
  issuer: "http://localhost:4000/ideacloud-id-gateway/us-central1/api",
  authorization_endpoint:
    "http://localhost:4000/ideacloud-id-gateway/us-central1/api/auth",
  token_endpoint:
    "http://localhost:4000/ideacloud-id-gateway/us-central1/api/token",
  userinfo_endpoint:
    "http://localhost:4000/ideacloud-id-gateway/us-central1/api/me",
  jwks_uri: "http://localhost:4000/ideacloud-id-gateway/us-central1/api/certs",
};

const PRODUCTION_ISSUER = {
  issuer: "https://id-gateway-qa-cloudrun-hvjnz66skq-uw.a.run.app/api",
  authorization_endpoint:
    "https://id-gateway-qa-cloudrun-hvjnz66skq-uw.a.run.app/api/auth",
  token_endpoint:
    "https://id-gateway-qa-cloudrun-hvjnz66skq-uw.a.run.app/api/token",
  userinfo_endpoint:
    "https://id-gateway-qa-cloudrun-hvjnz66skq-uw.a.run.app/api/me",
  jwks_uri: "https://id-gateway-qa-cloudrun-hvjnz66skq-uw.a.run.app/api/certs",
};

const LOCAL_ISSUER = USE_EXPRESS ? EXPRESS_ISSUER : FUNCTIONS_ISSUER;

export const FIREBASE_CONFIG = fb;

export const OIDC_CONFIG =
  window.location.href.indexOf(LOCALHOST) !== -1
    ? LOCAL_ISSUER
    : PRODUCTION_ISSUER;

export const FUNCTIONS_URL = OIDC_CONFIG.issuer;

// export const FUNCTIONS_URL =
//   window.location.href.indexOf(LOCALHOST) !== -1
//     ? 'http://localhost:4000'
//     : 'https://us-central1-ideacloud-id-gateway.cloudfunctions.net/api';
// // : 'https://idgateway-ideacloud.forgedx.com/api';

// export const OIDC_CONFIG = {
//   issuer: 'https://us-central1-ideacloud-id-gateway.cloudfunctions.net/api',
//   authorization_endpoint:
//     'https://us-central1-ideacloud-id-gateway.cloudfunctions.net/api/auth',
//   token_endpoint:
//     'https://us-central1-ideacloud-id-gateway.cloudfunctions.net/api/token',
//   userinfo_endpoint:
//     'https://us-central1-ideacloud-id-gateway.cloudfunctions.net/api/me',
//   jwks_uri:
//     'https://us-central1-ideacloud-id-gateway.cloudfunctions.net/api/certs'
// };

// export const OIDC_CONFIG = {
//   issuer: 'https://idgateway-ideacloud.forgedx.com/api',
//   authorization_endpoint: 'https://idgateway-ideacloud.forgedx.com/api/auth',
//   token_endpoint: 'https://idgateway-ideacloud.forgedx.com/api/token',
//   userinfo_endpoint: 'https://idgateway-ideacloud.forgedx.com/api/me',
//   jwks_uri: 'https://idgateway-ideacloud.forgedx.com/api/certs'
// };

export const OIDC_CLIENT_ID = "oidcCLIENT";

export const SALES_URL_PARAM = "bic";
export const INNOVATION_CENTER_URL_PARAM = "innovation";

export const DIGITAL_SIGN_PROPERTY = "interactiveDisplay";
export const PRESENTATION_SIGN_PROPERTY = "triggerDisplay";
export const AUX_SIGN_PROPERTY = "auxDisplay";

// export const API_BASE_URL =
//   'https://us-central1-forgedx-ideacloud.cloudfunctions.net/app/';
// export const API_BASE_URL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://us-central1-forgedx-ideacloud.cloudfunctions.net/app/'
//     : 'http://localhost:5000/forgedx-ideacloud/us-central1/app';
// export const FIREBASE_CONFIG = {
//   apiKey: 'AIzaSyClfzyRgonfvG2MKs6qh-PaYi-iQEz4slc',
//   authDomain: 'forgedx-ideacloud.firebaseapp.com',
//   databaseURL: 'https://forgedx-ideacloud.firebaseio.com',
//   projectId: 'forgedx-ideacloud',
//   storageBucket: 'forgedx-ideacloud.appspot.com',
//   messagingSenderId: '465998288192'
// };

// export const API_BASE_URL = fb.functionsURL;
export const API_BASE_URL =
  window.location.href.indexOf(LOCALHOST) !== -1
    ? "http://localhost:4000/api"
    : fb.functionsURL;
// export const FIREBASE_CONFIG = {
//   apiKey: 'AIzaSyB_CijdghSyGDhysIifPudngDDKHCIBd78',
//   authDomain: 'forgedx-ideacloud-v2.firebaseapp.com',
//   databaseURL: 'https://forgedx-ideacloud-v2.firebaseio.com',
//   projectId: 'forgedx-ideacloud-v2',
//   storageBucket: 'forgedx-ideacloud-v2.appspot.com',
//   messagingSenderId: '77821651729'
// };
const ENVIRONMENT = "qa";
export const PRODUCTION = ENVIRONMENT === "production";
// window.location.href.indexOf('192.168') === -1 &&
// window.location.href.indexOf('localhost') === -1 &&
// window.location.href.indexOf('-qa.') === -1;

export const USER_PROFILE = "users";
export const WEBSITE_CONFIG_COLLECTION = PRODUCTION ? "config" : "config_qa";

export const SESSIONS_COLLECTION = PRODUCTION ? "Session" : "Session_qa";

// QA and Prod share Interactions so Prod can Redirect to QA if needed
export const INTERACTIONS_COLLECTION = "Interaction";

export const CONFIG_COLLECTION = "Config";

export const COLLECTIONS_COLLECTION = PRODUCTION
  ? "collections"
  : "collections_qa";
export const SIMULATIONS_COLLECTION = PRODUCTION
  ? "simulations"
  : "simulations_qa";
