import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { BrowserRouter } from 'react-router-dom';
import './index.css';

import App from './App';
import AppActions from './Redux/AppRedux';
import createStore from './Redux/CreateStore';
import * as serviceWorker from './serviceWorker';

const initialState = window.__INITIAL_STATE__ || {
  firebase: { authError: null }
};
// Create a store and get back itself and its history object
const { store, history } = createStore();

const onUpdate = registration => {
  // Save Service Worker as Global Variable.
  window.myServiceWorker = registration;
  console.log('onUpdate', registration);
  store.dispatch(AppActions.AppSetUpdateAvailable());
};
const onSuccess = registration => {
  store.dispatch(AppActions.AppSetCached());
};

// serviceWorker.register({ onUpdate, onSuccess });
serviceWorker.unregister();

const rootEl = document.getElementById('root');

const Application = Componant => (
  <Provider store={store}>
    <BrowserRouter>
      {/* <ConnectedRouter history={history}> */}
      <Componant />
      {/* </ConnectedRouter> */}
    </BrowserRouter>
  </Provider>
);

render(Application(App), rootEl);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(Application(NextApp), rootEl);
  });
}
