import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Navigation from './Navigation';
import UpdateSnackbar from './Components/UpdateSnackbar';

const palette = {
  primary: {
    main: '#E11C8E'
  },
  white: '#FFFFFF',
  gray: {
    inputBackground: '#FAFAFA',
    disabled: '#CDCDCD',
    icon: '#BBBBBB',
    main: '#9A9A9A',
    outlinedButton: '#8C8C8C',
    link: '#4C4C4C'
  },
  other: {
    h3: '#142834'
  },
  information: {
    error: '#FF0000',
    success: '#3ADBA9'
  }
};
const fontWeight = {
  regular: 300,
  medium: 500,
  semibold: 700,
  bold: 800
};
const globalStyle = {
  transition: {
    fast: 'all .15s ease-in',
    slow: 'all .3s ease-in'
  },
  zIndex: {
    primary: 100,
    secondary: 200,
    tertiary: 300
  }
};
const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Gotham',
      '-apple-system',
      'Raleway',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    button: {
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '.7px',
      fontWeight: fontWeight.medium
    },
    h3: {
      fontSize: '24px',
      lineHeight: '33px',
      fontWeight: fontWeight.regular,
      color: palette.other.h3
    },
    body2: {
      fontSize: '16px',
      lineHeight: '16px',
      fontWeight: fontWeight.regular,
      color: palette.gray.main
    },
    display4: {
      fontSize: '8px',
      lineHeight: '12px',
      fontWeight: fontWeight.regular,
      color: palette.gray.link + ' !important',
      cursor: 'pointer'
    }
  },
  palette: palette,
  shadows: ["none"],
  props: {
    MuiButtonBase: {
      // disableRipple: true,
      disableUnderline: true
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: 30,
        minHeight: 36,
        minWidth: 130,
        '&$disabled': {
          backgroundColor: palette.gray.disabled,
          color: palette.white,
          cursor: 'not-allowed'
        }
      },
      outlined: {
        borderRadius: 30,
        minHeight: 36,
        minWidth: 130,
        color: palette.gray.outlinedButton,
        '&$disabled': {
          backgroundColor: palette.gray.disabled,
          color: palette.white,
          cursor: 'not-allowed'
        }
      }
    },
    MuiInput: {
      root: {
        backgroundColor: palette.gray.inputBackground,
        padding: '15px 10px',
        borderRadius: '6px',
        position: 'relative',
        overflow: 'hidden',
        '&:before': {
          display: 'none'
        },
        '&:after': {
          content: '""',
          borderBottom: 'none',
          borderRight: '0px solid ' + palette.information.success,
          position: 'absolute',
          left: 'auto !important',
          right: 0,
          top: 0,
          bottom: 0,
          height: '100%',
          width: 0,
          transform: 'none !important',
          transition: globalStyle.transition.fast + ' !important'
        },
        '&$error': {
          '&:after': {
            borderRightWidth: '8px',
            borderRightColor: palette.information.error
          }
        }
      }
    }
  }
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Navigation />
      <UpdateSnackbar />
    </MuiThemeProvider>
  );
}

export default App;
